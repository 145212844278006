import { replaceSelectors } from '~/helpers/pageDataTraverse/replaceSelectors';

const loremIpsum =
  // eslint-disable-next-line max-len
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed diam mi, vehicula sit amet nunc vel, faucibus volutpat.';

const tabTitleShared = {
  style: {
    color: 'var(--color-text-lightbg)',
    'font-family': 'var(--font-family-headings)',
  },
  tagName: 'h3',
  object: 'primitive',
  type: 'object',
  selector: 'to-be-replaced',
};

const tabContentShared = {
  textValue: loremIpsum,
  style: {
    color: 'var(--color-text-lightbg)',
    'line-height': '1.5',
    'font-family': 'var(--font-family-paragraphs)',
  },
  tagName: 'div',
  object: 'primitive',
  type: 'object',
  selector: 'to-be-replaced',
};

export default replaceSelectors(
  {
    contains: [
      {
        textValue: 'This is accordion 1',
        '@slot': 'tab-1-title',
        ...tabTitleShared,
      },
      {
        textValue: 'This is accordion 2',
        '@slot': 'tab-2-title',
        ...tabTitleShared,
      },
      {
        textValue: 'This is accordion 3',
        '@slot': 'tab-3-title',
        ...tabTitleShared,
      },
      {
        '@slot': 'tab-1-content',
        ...tabContentShared,
      },
      {
        '@slot': 'tab-2-content',
        ...tabContentShared,
      },
      {
        '@slot': 'tab-3-content',
        ...tabContentShared,
      },
    ],
    tagName: 'accordion-unit',
    object: 'meaning-unit',
    type: 'object',
  },
  false,
);

const hostnameRegex: RegExp =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

const urlRegex: RegExp =
  // eslint-disable-next-line max-len
  /^https:\/\/(?:www\.[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+|[a-zA-Z0-9-]+\.[a-zA-Z]{2,})+(?:\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

export const IS_SAFARI =
  navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
export const IS_FIREFOX = navigator.userAgent.indexOf('Firefox') > 0;

export const PANEL_DEBOUNCE_UPDATE_INTERVAL = 250;

export const REGEX_WEBSITE_HOSTNAME = new RegExp(hostnameRegex);
export const REGEX_URL = new RegExp(urlRegex);
export const REGEX_PROTOCOL = new RegExp(/^(https?:\/\/)/);

export const SUPPORTED_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif',
  'image/webp',
];
export const IMAGE_SIZE_LIMIT = 4.5 * 1024 * 1024;
export const MAX_IMAGE_COUNT = 30;
export const IMAGE_PER_BATCH = 10;

export const ANALYSIS_STATUS_NEW = 'NEW';
export const ANALYSIS_STATUS_NEEDS_QUOTA = 'NEEDS_QUOTA';
export const ANALYSIS_STATUS_PROCESSED = 'SUCCESS';
export const ANALYSIS_STATUS_FAILED = 'FAILED';

export const ERROR_STATUSES = {
  403: {
    image: '/assets/403.png',
    heading: 'Access Denied',
    message: (
      <>
        If you think you should have access to this page
        <br /> Please{' '}
        <a href="https://www.upflowy.com/contact" target="_blank">
          contact us
        </a>
      </>
    ),
  },
  404: {
    image: '/assets/404.png',
    heading: 'Page not found',
    message: <>We couldn't find the page you were looking for.</>,
  },
};

export const TOOLTIPS_FOR_DRAG_DROP_TO_ADD = {
  TO_SECTION: 'Drop to add section',
  TO_COLUMN: 'Drop to add column',
  default: 'Drop to add',
};

export const TOOLTIPS_FOR_DRAG_DROP_TO_MOVE = {
  MOVE_SECTION: 'Move section here',
  TO_SECTION: 'Drop to move into section',
  TO_COLUMN: 'Drop to move into column',
  default: 'Drop to move',
};

export const GENERIC_HEADING_TEXT_UNIT_STYLE = {
  'line-height': '1.5',
  'font-family': 'var(--font-family-headings)',
  color: 'var(--color-text-lightbg)',
};

export const GENERIC_BODY_TEXT_UNIT_STYLE = {
  ...GENERIC_HEADING_TEXT_UNIT_STYLE,
  'font-family': 'var(--font-family-paragraphs)',
};

export const VARIANTS_KEYWORDS_LIMIT = 200;
export const NAME_WORD_LIMIT = 128;
export const MAX_LENGTH_PUBLISH_SETTINGS = 80;

export const DATASET_CSV_MAX_SIZE = 2 * 1024 * 1024;

import { Affix, Flex, Group, Loader, Paper } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ListVariant from './components/ListVariant';
import Portview from './components/Portview';
import { PreviewViewer } from './components/PreviewViewer';
import TopNavbar from './components/TopNavbar';
import { forceRender } from './utils';

import useErrorHandling from '../../hooks/useErrorHandling/useErrorHandling';
import { HideChat } from '../Studio/Studio.styles';

import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import VariantDropdownButton from '~/components/VariantDropdownButton/VariantDropdownButton';
import { ParsedLandingpageObject } from '~/global.types';
import renderPage from '~/helpers/renderPage/renderPage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider.tsx';
import { firstFetch } from '~/services/PageServices';
import { GetPageVariantData, getPageVariants } from '~/services/PageSetServices/PageSetServices';

const Preview = () => {
  const handlingError = useErrorHandling();
  const [tab, setTab] = useState('desktop');
  const [loading, setLoading] = useState(false);
  const [srcDoc, setSrcDoc] = useState('');
  const [variantList, setVariantList] = useState<GetPageVariantData[]>([]);
  const [isPageSet, setIsPageSet] = useState(false);
  const { nanoId = '' } = useParams();
  const [listUpdatedId, setListUpdatedId] = useState<string>();

  const { isLoading, setIsLoading, selectedVariant, getVariantView } = usePageSet();

  const placeholderRegex = /\{\{\s*([^}]+?)\s*\}\}/g;

  const variantError = () => {
    notifications.show({
      color: 'red',
      message: 'Failed to retrieve page variants.',
      autoClose: 3000,
    });
  };

  const doPageSetOperations = async (pageSetId?: string, pageVariantId?: string) => {
    if (pageSetId) {
      setIsPageSet(true);
      if (!pageVariantId) {
        try {
          const variantsResponse = await getPageVariants({ pageSetId });
          setVariantList(variantsResponse.data);
          if (variantsResponse.data[0]) {
            return await getVariantView(selectedVariant?.nanoId ?? variantsResponse.data[0].nanoId);
          }
        } catch (error) {
          variantError();
        }
      } else {
        try {
          return await getVariantView(pageVariantId);
        } catch (error) {
          variantError();
        }
      }
    }
  };

  const replaceDatasetContent = (content: string, data?: Record<string, string>) => {
    setSrcDoc(
      content.replaceAll(placeholderRegex, (_, dataKey) => {
        return data?.[dataKey] ?? '';
      }),
    );
  };

  const asyncRenderPage = async (res: ParsedLandingpageObject, variantId?: string) => {
    try {
      const pageSetId = res.pageSetId;

      const variantData = await doPageSetOperations(pageSetId, variantId);

      const pageContent = await renderPage(
        nanoId,
        res.workspaceId,
        JSON.parse(res.content ?? ''),
        true,
      );
      pageContent && replaceDatasetContent(pageContent, variantData?.externalData);
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Error in loading page!',
        autoClose: 3000,
      });
      console.error('Error in rendering page:', error);
    }
  };

  const listClickHandler = (variantNanoId: string) => {
    setListUpdatedId(variantNanoId);
  };

  useEffect(() => {
    setLoading(true);
    //setting this early to close the dropdown
    setIsLoading(true);
    firstFetch(nanoId)
      .then((page) => asyncRenderPage(page, listUpdatedId))
      .catch(handlingError)
      .finally(() => setLoading(false));
  }, [listUpdatedId]);

  useEffect(() => {
    setLoading(true);
    firstFetch(nanoId)
      .then(asyncRenderPage)
      .catch(handlingError)
      .finally(() => setLoading(false));
  }, []);

  // Please do not delete this forceRender (reloading the frame) despite it makes no sense
  // Historical context - https://gitlab.com/upflowy/landingpage-studio/-/merge_requests/317
  //
  // This is to resolve a strange issue that has been surfaced all of a sudden in mid 2024.
  // You won't find the problem in your local dev, but blank renders will occur in
  // testing and production. Until we have the time and the go-ahead to deeply investigate why
  // iframe rendering got stuck without a full iframe reload, despite having HTML fully loaded and
  // Web Component fully registered, we will need this strange fix in place.
  useEffect(forceRender(srcDoc), [srcDoc]);

  const loadingViewer =
    (isPageSet && isLoading && listUpdatedId !== selectedVariant?.nanoId) || loading;

  return (
    <ScreenContainer>
      <HideChat />
      {isPageSet && (
        <Affix position={{ left: 8, top: 8 }} zIndex={9999}>
          <Paper shadow="xs" p="0" withBorder radius={10} w="fit-content">
            <Group justify="space-between" p={8}>
              <VariantDropdownButton
                isLoading={isLoading}
                selectedVariantName={selectedVariant?.name}
                popoverWidth={150}
              >
                <ListVariant variantList={variantList} clickHandler={listClickHandler} />
              </VariantDropdownButton>
            </Group>
          </Paper>
        </Affix>
      )}
      <TopNavbar setTab={setTab} tab={tab} />
      <Portview type={tab}>
        {loadingViewer && (
          <Flex h="100%" justify="center" align="center">
            <Loader />
          </Flex>
        )}
        {!loadingViewer && (
          <PreviewViewer
            sandbox="allow-downloads allow-popups allow-same-origin allow-scripts allow-forms"
            srcDoc={srcDoc}
            style={{ display: loadingViewer ? 'none' : 'block' }}
          />
        )}
      </Portview>
    </ScreenContainer>
  );
};

export default Preview;

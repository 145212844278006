import awsAPIClient from '~/helpers/ApiClient/ApiClient';

export type BrandToken = Record<string, string>;

export interface Brand {
  nanoId: string;
  brandParameters: BrandToken[];
  type: string;
  definition: string;
  content: string;
  userId: string;
  createdAt: string;
  relatedEntityId: string;
  updatedDate: string;
}

export const saveBrandAnalysis = async ({
  nanoId,
  keyValues,
}: {
  nanoId: string;
  keyValues: Record<string, string | number>;
}): Promise<{ message: string }> => {
  const response = await awsAPIClient.post<{ message: string }>('/save-brand-analysis', {
    nanoId,
    keyValues,
  });
  return response;
};

export const getBrandAnalysis = async (workspaceId: string): Promise<{ data: Brand }> => {
  return await awsAPIClient.post('/get-brand-analysis', {
    workspaceId,
  });
};

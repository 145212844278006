import styled from 'styled-components';

export const DraggableElement = styled.div`
  width: max-content;

  & > * {
    margin: 0;
    pointer-events: none;
  }

  &:hover {
    box-shadow:
      0px 1px 3px 0px #0000002f,
      0px 4px 3.799999952316284px 0px #0000001a;
    opacity: 0.8;
  }
`;

export const DraggableCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 100px;
  border-radius: 4px;
  border: 1px solid #f3f1ff;
  box-shadow: 0px 2px 4px 0px #d5d2f0;
  background: #fff;
`;

import awsAPIClient from '~/helpers/ApiClient/ApiClient';

interface CreateDataSetResponse {
  message: string;
  data: {
    success: boolean;
    message: string;
  };
}

export const getDataSet = async ({ pageSetId }: { pageSetId: string }) => {
  return awsAPIClient.post<Blob>('/get-data-set', { pageSetId });
};

export const createDataSet = async ({
  pageSetId,
  csvData,
}: {
  pageSetId: string;
  csvData: {
    bufferData: string;
    fileName: string;
  };
}) => {
  return awsAPIClient.post<CreateDataSetResponse>('/create-data-set', {
    pageSetId,
    csvData,
  });
};

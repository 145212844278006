import { Product } from '~/global.types';
import awsAPIClient from '~/helpers/ApiClient/ApiClient';

export const getStripeCustomerPortal = () => {
  return awsAPIClient.post<{ portalUrl: string }>('/create-stripe-customer-billing-portal', {
    redirectUrl: `${window.location.origin}/intent/dashboard`,
  });
};

export const createStripeCustomer = () => {
  return awsAPIClient.post('/create-stripe-customer', {});
};

export const subscribeUserToFreeStripePlan = () => {
  return awsAPIClient.post('/subscribe-user-to-stripe-plan', { plan: 'FREE' });
};

export const setupDefaultSubscription = (product: Product = 'INTENT') => {
  return awsAPIClient.post('/setup-default-subscription', { product });
};

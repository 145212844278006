import { notifications } from '@mantine/notifications';

import { DATASET_CSV_MAX_SIZE } from '~/constants';

const showErrorNotification = (message: string) => {
  notifications.show({
    color: 'red',
    message,
    autoClose: 3000,
  });
};

export const validateCSVFile = (newFile: File, uploadFile: (file: File) => Promise<void>) => {
  if (newFile.size > DATASET_CSV_MAX_SIZE) {
    showErrorNotification(
      `${newFile.name} exceeds the maximum size limit of ${(DATASET_CSV_MAX_SIZE / 1024 / 1024).toFixed(1)}MB.`,
    );
    return false;
  }

  const reader = new FileReader();
  reader.onloadend = () => {
    if (reader.error) {
      showErrorNotification(`${newFile.name} failed to upload.`);
      return;
    }

    const content = reader.result as string;

    if (content.trim() === '') {
      showErrorNotification(`${newFile.name} failed to upload. The file is empty.`);
      return;
    }

    const rows = content.split(/\r?\n/);
    const headers = rows[0]?.split(',');

    if (headers.length === 0) {
      showErrorNotification(`${newFile.name} failed to upload. The file does not contain headers.`);
      return;
    }

    if (headers[0] !== 'variant_keywords') {
      showErrorNotification(
        `${newFile.name} failed to upload. The first column should always include keywords.`,
      );
      return;
    }

    const invalidHeaders = headers.filter((header) => !/^[a-zA-Z0-9 _-]+$/.test(header));

    if (invalidHeaders.length > 0) {
      showErrorNotification(
        `${newFile.name} failed to upload. Remove special characters from the column headers.`,
      );
      return;
    }

    uploadFile(newFile);
  };
  reader.readAsText(newFile);
};

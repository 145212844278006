import {
  GetLeadsResponse,
  GetQuotaInfoResponse,
  GetTrackingStatusResponse,
  TestWebhookResponse,
} from 'global.types';

import awsApiClient from '~/helpers/ApiClient/ApiClient';

export const getPaginatedLeads = async ({
  workspaceId,
  propertyId,
  limit,
  offset,
  orderBy,
}: {
  workspaceId: string;
  propertyId: string;
  limit: number;
  offset: number;
  orderBy: string;
}) => {
  if (import.meta.env?.VITE_APP_USE_MOCKS === 'true') {
    return new Promise<GetLeadsResponse>((resolve) => {
      setTimeout(() => {
        resolve(import('../../mocks/getPaginatedLeads.data.json') as unknown as GetLeadsResponse);
      }, 1000);
    });
  }
  return awsApiClient.post<GetLeadsResponse>('/get-leads', {
    workspaceId,
    propertyId,
    limit,
    offset,
    orderBy,
  });
};

export const getTrackingStatus = async ({
  workspaceId,
  propertyId,
}: {
  workspaceId: string;
  propertyId: string;
}) => {
  if (import.meta.env?.VITE_APP_USE_MOCKS === 'true') {
    return import(
      '../../mocks/getTrackingStatus.data.json'
    ) as unknown as GetTrackingStatusResponse;
  }
  return awsApiClient.post<GetTrackingStatusResponse>('/get-tracking-status', {
    workspaceId,
    propertyId,
  });
};

export const getQuotaInfo = async ({
  workspaceId,
  propertyId,
}: {
  workspaceId: string;
  propertyId: string;
}) => {
  return awsApiClient.post<GetQuotaInfoResponse>('/get-quota-info', {
    workspaceId,
    propertyId,
  });
};

export const testWebhook = async ({
  workspaceId,
  propertyId,
  webhookUrl,
}: {
  workspaceId: string;
  propertyId: string;
  webhookUrl: string;
}) => {
  return awsApiClient.post<TestWebhookResponse>('/test-webhook', {
    workspaceId,
    propertyId,
    webhookUrl: encodeURIComponent(webhookUrl),
  });
};

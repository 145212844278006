import { Flex, Stack, Title } from '@mantine/core';
import { IconCode, IconIcons } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import AddElement from './AddElement';
import { DraggableCard } from './AddElement.styles';
import { generateStylesList, StylesList } from './generateStyle';
import presetForAccordion from './presets/accordion';

import BasePanel from '../BasePanel/BasePanel';
import { BrandToken, parseCSSVariables } from '../StylePanel/styleProcessing';

import IconAccordion from '~/components/CustomIcons/IconAccordion';
import { ToolbarPanelProps } from '~/global.types';
import { getStyleWithString } from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';

const AddElementPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  const [brandTokens, setBrandTokens] = useState<BrandToken[]>([]);
  const [stylesList, setStylesList] = useState<StylesList[]>([]);
  const { featureFlags } = useFeatureFlags();

  useEffect(() => {
    if (opened) {
      msg({ type: 'trigger-broadcast-page-data' });
    }
  }, [opened]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        const { head } = JSON.parse(data.pageData);
        const { textValue: rootStyleCSS } = head.find(getStyleWithString(':root'));
        setBrandTokens(parseCSSVariables(rootStyleCSS));
      }
      if (data.type === 'head-edited') {
        msg({ type: 'trigger-broadcast-page-data' });
      }
    },
    [opened],
  );

  useEffect(() => {
    setStylesList(generateStylesList(brandTokens));
  }, [brandTokens]);

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-element')}
      label="Add element options"
      title="Elements"
      icon={<IconIcons />}
    >
      <Stack mt={24} gap={32}>
        <Stack gap={16}>
          <Title fw={600} size={16}>
            Buttons
          </Title>
          {stylesList.map((styleColorGroup, groupIndex) => (
            <div key={groupIndex}>
              <Flex gap={8} pt={1} pb={1}>
                {styleColorGroup.map((userStyle, index) => (
                  <AddElement
                    component={<a>Button</a>}
                    key={index}
                    dataItem={{
                      tagName: 'a',
                      textValue: 'Button',
                      '@role': 'button',
                      '@href': '',
                      object: 'primitive',
                      type: 'button',
                      '@target': '_blank',
                      style: userStyle[0],
                    }}
                    userStyle={{ ...userStyle[1] }}
                  />
                ))}
              </Flex>
            </div>
          ))}
        </Stack>
        {featureFlags.codeEmbedAccess?.enabled && (
          <Stack gap={16}>
            <Title fw={600} size={16}>
              Embed code
            </Title>

            <AddElement
              aria-label="Drag to add Code Embed"
              component={
                <DraggableCard>
                  <IconCode height={24} color="#5a1cec" />
                </DraggableCard>
              }
              dataItem={{
                tagName: 'code-embed',
                object: 'primitive',
                type: 'object',
              }}
            />
          </Stack>
        )}
        {featureFlags.accordionAccess?.enabled && (
          <Stack gap={16}>
            <Title fw={600} size={16}>
              Accordion
            </Title>

            <AddElement
              aria-label="Drag to add Accordion"
              component={
                <DraggableCard>
                  <IconAccordion height={24} color="#5a1cec" />
                </DraggableCard>
              }
              dataItem={presetForAccordion}
            />
          </Stack>
        )}
      </Stack>
    </BasePanel>
  );
};

export default AddElementPanel;

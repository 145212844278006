export const validateCodeEmbed = (value: string) => {
  const sanitizedValue = value
    .replace(/<script.*?<\/script>/gs, '')
    .replace(/<style.*?<\/style>/gs, '')
    .replace(/<([a-z][a-z0-9]*)[^>]*?(\/?)>/gi, '<$1$2>');

  const valueWithParent = `<body>${sanitizedValue}</body>`;

  const parser = new DOMParser();
  const doc = parser.parseFromString(valueWithParent, 'application/xhtml+xml');
  const errorNode = doc.querySelector('parsererror');
  if (errorNode || value.trim() === '') {
    return 'The embed code you entered is not valid. Please check the format and try again.';
  }
  return null;
};

import { MAX_LENGTH_PUBLISH_SETTINGS } from '~/constants';

export const validateSlug = (slug: string) => {
  if (slug.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return 'URL too long: URL slug should be no longer than 80 characters';
  }

  if (!/^(?![_\-0-9])([a-z0-9]+(?:[-_][a-z0-9]+)*)(?<![-_])$/.test(slug)) {
    // eslint-disable-next-line max-len
    return 'Invalid slug: Only letters, numbers, hyphens, and underscores are allowed. It must not start or end with a hyphen or underscore, and multiple consecutive hyphens or underscores are not allowed';
  }

  return null;
};

export const validateTitle = (title: string) => {
  if (title.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return 'Too long: Page title should be no longer than 80 characters';
  }

  if (!/^[a-zA-Z0-9- ]+$/.test(title)) {
    return 'Invalid Characters: Please avoid using special characters in the title';
  }

  return null;
};

import {
  Badge,
  Box,
  Button,
  Flex,
  Skeleton,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconArrowBarUp,
  IconPlayerPlay,
  IconWorldWww,
} from '@tabler/icons-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import BasePanel from '../BasePanel/BasePanel';
import {
  FormDomainType,
  FormForNotContentType,
  FormType,
} from '../PublishSettings/PublishSettingsMenu';

import IconSyncVariants from '~/components/CustomIcons/IconSyncVariants';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { publishPage } from '~/services/PageServices';
import { updatePageVariant } from '~/services/PageSetServices/PageSetServices';
import { addDomain } from '~/services/WorkspaceServices';

export interface PublishGoLivePanelProps {
  opened: boolean;
  onClickToClose: () => void;
  loadDomain: boolean;
  setIsCurrentVariantEdited: Dispatch<SetStateAction<boolean>>;
  form: FormType;
  formForNotContent: FormForNotContentType;
  formDomain: FormDomainType;
}

const PublishGoLivePanel = ({
  opened,
  onClickToClose,
  loadDomain,
  setIsCurrentVariantEdited,
  form,
  formForNotContent,
  formDomain,
}: PublishGoLivePanelProps) => {
  const { pageSetId, blueprintEdited, variantList, selectedVariant, publishVariants } =
    usePageSet();
  const [isPublished, setIsPublished] = useState(false);
  const [publishing, setPublishing] = useState(false);

  useEffect(() => {
    if (opened) {
      setIsPublished(
        pageSetId
          ? !!selectedVariant?.lastPublishedDate
          : !!formForNotContent.values.lastPublishedDate,
      );
    }
  }, [opened, formForNotContent]);

  const onClickToOpenConfirmationPanel = () => {
    msg({
      type: 'open-toolbar-panel',
      openToolbarPanel: 'publish-settings',
      openToolbarSubPanel: 'publish-confirmation',
    });
  };

  const variantNodeEdits = Object.keys(blueprintEdited || {}).length;

  const formHasErrors =
    Object.keys(form.errors).length + Object.keys(formDomain.errors).length !== 0;

  const handleClickToOpenSyncVariantModal = () => {
    msg({ type: 'show-modal', modal: 'sync-variant' });
  };

  const addNewDomain = async () => {
    try {
      const response = await addDomain({
        workspaceId: formForNotContent.values.workspaceId ?? '',
        domain: formDomain.values.domain,
      });

      if (response.vercelResponse && !response.vercelResponse?.verified) {
        formDomain.setValues({
          error: true,
        });
        notifications.show({
          color: 'red',
          message: 'This domain name is already registered in Vercel! Please try another one.',
          autoClose: 3000,
        });
        return;
      }
      notifications.show({
        color: 'blue',
        message: 'Add domain successfully! Continue publishing...',
        autoClose: 3000,
      });
      formDomain.setValues({
        cname: response.cnameValue,
        error: false,
      });
    } catch (error) {
      formDomain.setValues({
        error: true,
      });
      notifications.show({
        color: 'red',
        message: 'Unable to add domain, it may already exist!',
        autoClose: 3000,
      });
    }
  };

  const publish = async () => {
    try {
      if (pageSetId) {
        await publishVariants();
      } else {
        await publishPage({
          nanoId: formForNotContent.values.nanoId,
        });

        msg({
          type: 'open-toolbar-panel',
          openToolbarPanel: 'publish-settings',
          openToolbarSubPanel: 'publish-confirmation',
        });
        notifications.show({
          color: 'blue',
          message: 'Page published successfully!',
          autoClose: 3000,
        });
        setPublishing(false);
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Unable to publish page!',
        autoClose: 3000,
      });
      setPublishing(false);
    }
  };

  const handlePublish = async () => {
    setPublishing(true);

    if (opened && formDomain.isDirty() && formDomain.values.domain) {
      await addNewDomain();
      formDomain.resetDirty(formDomain.values);
    }
    if (opened && form.isDirty()) {
      msg({
        type: selectedVariant ? 'editing-publish-settings-variant' : 'editing-publish-settings',
        outputData: { ...form.values },
      });
    } else {
      publish();
    }
  };

  useViewerMessage(
    async ({ data }) => {
      if (
        ['update-page-content-success', 'editing-publish-settings-success'].includes(data.type) &&
        opened &&
        publishing
      ) {
        if (selectedVariant) {
          try {
            await updatePageVariant({
              nanoId: formForNotContent.values.nanoId,
              definition: form.values.urlSlug,
              title: form.values.title,
              description: form.values.description.length > 0 ? form.values.description : undefined,
            });
            setIsCurrentVariantEdited(true);
          } catch (error) {
            notifications.show({
              color: 'red',
              message:
                error instanceof Error
                  ? JSON.parse(error.message).message
                  : 'Failed to update page variant settings',
              autoClose: 3000,
            });
            setPublishing(false);
            return;
          }
        }
        form.resetDirty(form.values);
        await publish();
      }

      if (data.type === 'update-page-content-failed' && opened && publishing) {
        notifications.show({
          color: 'red',
          message: JSON.parse(data.outputData.errorMessage).message,
          autoClose: 3000,
        });
        setPublishing(false);
      }

      if (data.type === 'show-modal' && data.modal === 'publish-completed') {
        setPublishing(false);
      }
    },
    [opened, form, formForNotContent, publishing, selectedVariant],
  );

  return (
    <>
      <BasePanel
        opened={opened}
        onClickToClose={onClickToClose}
        label="Publish"
        title="Go Live"
        icon={<IconArrowBarUp />}
        publishButton={
          <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
            <Button variant="default" w="100%" onClick={() => window.open('./preview', '_newtab')}>
              <IconPlayerPlay />
              <Text ml={8}>{pageSetId ? 'Preview variant pages' : 'Preview page'}</Text>
            </Button>
            {pageSetId && variantNodeEdits > 0 && (
              <Button
                variant="default"
                w="100%"
                mt={10}
                onClick={handleClickToOpenSyncVariantModal}
              >
                <IconSyncVariants color="var(--mantine-color-upflowy-purple-filled)" />
                <Text ml={8}>
                  {`Sync ${variantNodeEdits} edit${variantNodeEdits !== 1 ? 's' : ''}`}
                </Text>
              </Button>
            )}
            <Tooltip
              disabled={variantNodeEdits === 0}
              multiline
              w={260}
              // eslint-disable-next-line max-len
              label="You cannot publish until all changes have been synced. Please sync your changes to enable publishing"
              withArrow
              color="var(--mantine-color-gray-7)"
              zIndex={10000}
              ta="center"
              position="top"
            >
              <Button
                w="100%"
                loading={publishing}
                disabled={publishing || formHasErrors || variantNodeEdits > 0}
                loaderProps={{ type: 'dots' }}
                onClick={handlePublish}
                mt={10}
                aria-label="Publish"
              >
                <Text>{pageSetId ? `Publish all ${variantList?.length} variants` : 'Publish'}</Text>
              </Button>
            </Tooltip>
            {variantNodeEdits > 0 && (
              <Flex justify="center" align="center" mt={10} direction="row" gap={5}>
                <IconAlertTriangle color="#eb5757" size={18} />
                <Text size="xs" c="#eb5757">
                  All changes must be synced before publishing
                </Text>
              </Flex>
            )}
          </Box>
        }
      >
        <Flex direction="column" gap={32} w="100%">
          {isPublished && (
            <Flex direction="column">
              <Flex justify="space-between" align="center" w="100%">
                <Text size="sm" mt="md" fw="500">
                  Go live status
                </Text>
                <Badge mt="md" color="#ebfbee" size="xs" w="56">
                  <Text size="10" fw="600" c="#2b8a3e">
                    ● LIVE
                  </Text>
                </Badge>
              </Flex>
              <Text
                c="#5a1cec"
                size="sm"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => onClickToOpenConfirmationPanel()}
              >
                View subdomain instructions
              </Text>
            </Flex>
          )}
          <Flex direction="column" mt="md">
            <Flex align="center">
              <ThemeIcon color="#f3eaff">
                <IconWorldWww color="#6d3fee" size="18" stroke={1} />
              </ThemeIcon>
              <Title order={4} ml={8} fw={400}>
                Domain Settings
              </Title>
            </Flex>

            <Skeleton visible={loadDomain} height={80} mt="md">
              <TextInput
                label="Add a Domain or Subdomain"
                description={
                  formDomain.values.domain && !formDomain.isDirty()
                    ? 'Please contact us for updating the domain'
                    : "Type the domain or subdomain exactly as you'd want it to appear"
                }
                disabled={publishing || (!!formDomain.values.domain && !formDomain.isDirty())}
                placeholder="www.yourcompany.com"
                size="sm"
                {...formDomain.getInputProps('domain')}
              />
            </Skeleton>
          </Flex>
          <Flex direction="column">
            <Flex align="center" mt="md">
              <img
                width={24}
                alt="Google Tag Manager"
                src={`/assets/icon-googletagmanager-blue.png`}
              />
              <Title order={4} ml={8} fw={400}>
                Google Tag Manager
              </Title>
            </Flex>

            <TextInput
              label="Container ID"
              size="sm"
              mt="md"
              placeholder="Tag manager's container ID"
              disabled={publishing}
              {...form.getInputProps('googleTagManagerId')}
            />
          </Flex>
        </Flex>
      </BasePanel>
    </>
  );
};

export default PublishGoLivePanel;

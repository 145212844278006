import { CSSProperties, useState } from 'react';

import { MenuContainer } from './EditToolbar.styles';
import { BackgroundColorTool } from './Tools/BackgroundColorTool/BackgroundColorTool';
import BorderTool from './Tools/BorderTool/BorderTool';
import CssInspectorTool from './Tools/CssInspectorTool/CssInspectorTool';
import DeleteTool from './Tools/DeleteTool/DeleteTool';
import DuplicateTool from './Tools/DuplicateTool/DuplicateTool';
import ImageAlignTool from './Tools/ImageAlignTool/ImageAlignTool';
import LineHeightTool from './Tools/LineHeightTool/LineHeightTool';
import MovingSectionTool from './Tools/MovingTool/MovingSectionTool';
import MovingTool from './Tools/MovingTool/MovingTool';
import PaddingTool from './Tools/PaddingTool/PaddingTool';
import AddLinkTool from './Tools/SelectedTextAddLinkTool/SelectedTextAddLinkTool';
import {
  FontBackgroundColorTool,
  FontColorTool,
} from './Tools/SelectedTextFontColorTool/SelectedTextFontColorTool';
import FontSizeTool from './Tools/SelectedTextFontSizeTool/SelectedTextFontSizeTool';
import {
  BoldTool,
  ItalicTool,
  StrikeThroughTool,
  UnderlineTool,
} from './Tools/SelectedTextFontTool/SelectedTextFontTool';
import TagNameTool from './Tools/TagNameTool/TagNameTool';
import TextAlignTool from './Tools/TextAlignTool/TextAlignTool';
import VerticalAlignTool from './Tools/VerticalAlignTool/VerticalAlignTool';

import { ViewerData } from '~/global.types';
import {
  handleClickToEmitEvent,
  modifyColumnLayoutElementStyleInIframe,
  modifyStyleInIframe,
} from '~/helpers/viewerInteractions/viewerUpdateUponEditing';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

type EditToolbarProps = {
  tagName: string;
  style?: CSSProperties;
  hasSlot?: string;
  allowDevMode: {
    enabled: boolean;
  };
};

const EditToolbar = ({ tagName, style, hasSlot, allowDevMode }: EditToolbarProps) => {
  const [selection, setSelection] = useState('');
  const [viewerData, setViewerData] = useState<Partial<ViewerData>>({});

  useViewerMessage(({ data }) => {
    if (data?.type === 'element-bounding-client-update') {
      setSelection(data.elementSelector || '');
    }

    if (
      data?.type === 'element-selected-in-viewer' ||
      data?.type === 'text-range-selected-in-viewer'
    ) {
      setViewerData(data);
    }
  }, []);

  const clickHandler = (key: string, value: string) => {
    if (selection) handleClickToEmitEvent(selection, key, value);
    else modifyColumnLayoutElementStyleInIframe(viewerData, key, value);
  };

  const handleUpdate = (key: string, newValue: string) => {
    modifyStyleInIframe(key, newValue, viewerData);
  };

  const isTextNode =
    hasSlot &&
    ['span', 'div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName.toLowerCase()) &&
    !viewerData.isLinkWrappedMedia;

  const isImageNode = hasSlot && ['img', 'picture'].includes(tagName.toLowerCase());

  const isMeaningUnit = tagName.endsWith('-UNIT');

  const isRemovable =
    viewerData.elementSelector ||
    (viewerData.relatedColumnElementSelectors?.length || 0) > 0 ||
    viewerData.columnLayoutInnerComponentType === 'section';

  const isColumn = viewerData.columnLayoutInnerComponentType?.startsWith('column-');

  const isSection = viewerData.columnLayoutInnerComponentType === 'section';

  const isButtonNode =
    hasSlot && (['a'].includes(tagName.toLowerCase()) || viewerData.isLinkWrappedMedia);

  const isCodeEmbedPlaceholder = tagName.toLowerCase() === 'code-embed';

  const isDuplicable = isSection || isColumn || isTextNode || isImageNode || isButtonNode;

  return (
    <MenuContainer style={style}>
      {isSection && <MovingSectionTool viewerData={viewerData} />}

      {isTextNode && viewerData.type === 'text-range-selected-in-viewer' && (
        <>
          <FontBackgroundColorTool viewerData={viewerData} />
          <FontColorTool viewerData={viewerData} />
          <BoldTool viewerData={viewerData} />
          <ItalicTool viewerData={viewerData} />
          <StrikeThroughTool viewerData={viewerData} />
          <UnderlineTool viewerData={viewerData} />
          <AddLinkTool viewerData={viewerData} />
          <FontSizeTool viewerData={viewerData} />
        </>
      )}

      {isTextNode && viewerData.type === 'element-selected-in-viewer' && (
        <>
          <MovingTool viewerData={viewerData} />
          <FontColorTool viewerData={viewerData} />
          <FontSizeTool viewerData={viewerData} />
        </>
      )}

      {isTextNode && (
        <>
          <TagNameTool handleClickToEmitEvent={clickHandler} />
          <LineHeightTool handleClickToEmitEvent={clickHandler} />
          <TextAlignTool handleClickToEmitEvent={clickHandler} viewerData={viewerData} />
        </>
      )}

      {isImageNode && (
        <>
          <MovingTool viewerData={viewerData} />
          <ImageAlignTool handleClickToEmitEvent={clickHandler} viewerData={viewerData} />
        </>
      )}

      {isMeaningUnit && <MovingTool viewerData={viewerData} />}

      {isColumn && (
        <VerticalAlignTool handleClickToEmitEvent={clickHandler} viewerData={viewerData} />
      )}

      {isButtonNode && viewerData.type === 'element-selected-in-viewer' && (
        <>
          <MovingTool viewerData={viewerData} />
          <ImageAlignTool handleClickToEmitEvent={clickHandler} viewerData={viewerData} />
          <FontColorTool viewerData={viewerData} />
          <FontSizeTool viewerData={viewerData} />
        </>
      )}

      {isButtonNode && viewerData.type === 'text-range-selected-in-viewer' && (
        <>
          <BoldTool viewerData={viewerData} />
          <ItalicTool viewerData={viewerData} />
          <StrikeThroughTool viewerData={viewerData} />
          <UnderlineTool viewerData={viewerData} />
        </>
      )}

      {!isCodeEmbedPlaceholder && (
        <>
          <PaddingTool viewerData={viewerData} handleUpdate={handleUpdate} />
          <BorderTool handleUpdate={handleUpdate} viewerData={viewerData} />
          <BackgroundColorTool viewerData={viewerData} />
        </>
      )}

      {isDuplicable && <DuplicateTool viewerData={viewerData} />}

      {isRemovable && <DeleteTool viewerData={viewerData} />}

      {allowDevMode.enabled && !isCodeEmbedPlaceholder && (
        <CssInspectorTool viewerData={viewerData} />
      )}
    </MenuContainer>
  );
};

export default EditToolbar;

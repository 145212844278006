import { Menu, Tooltip } from '@mantine/core';
import { IconLineHeight } from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

const lineHeights = [1, 1.5, 2, 2.5, 3];

type LineHeightToolProps = {
  handleClickToEmitEvent: (key: string, value: string) => void;
};

const LineHeightTool = ({ handleClickToEmitEvent }: LineHeightToolProps) => {
  return (
    <Menu aria-label="line-height-tool" position="bottom" zIndex={1001}>
      <Tooltip label="Line height" color="Gray" withArrow zIndex={1001}>
        <Menu.Target>
          <StyledActionIcon variant="transparent">
            <IconLineHeight size={20} stroke={1.5} />
          </StyledActionIcon>
        </Menu.Target>
      </Tooltip>
      <Menu.Dropdown>
        <Menu.Label>Line height</Menu.Label>
        {lineHeights.map((lineHeight) => (
          <Menu.Item
            key={lineHeight}
            onClick={() => handleClickToEmitEvent('lineHeight', lineHeight.toString())}
          >
            {lineHeight}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default LineHeightTool;

import { Box, Flex, ScrollArea, TextInput, UnstyledButton } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { useRef } from 'react';

import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

interface ListVariantProps {
  variantList: GetPageVariantData[];
  clickHandler: (variantNanoId: string) => void;
}

const ListVariant: React.FC<ListVariantProps> = ({ variantList, clickHandler }) => {
  const [filter, setFilter] = useInputState('');
  const viewportRef = useRef<HTMLDivElement>(null);

  const filteredVariantList = variantList
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((variant: { name: string }) =>
      variant.name.toLowerCase().includes(filter.toLowerCase()),
    );

  const handleClickToGetVariant = (variantNanoId: string) => () => {
    clickHandler(variantNanoId);
  };

  return (
    <>
      <Box p={4}>
        <TextInput
          value={filter}
          onChange={setFilter}
          placeholder="Filter variants"
          leftSection={<IconSearch size={18} />}
          variant="filled"
        />
      </Box>

      <ScrollArea.Autosize viewportRef={viewportRef} mah={400} type="never" p={12}>
        {filteredVariantList.map((variant: GetPageVariantData) => (
          <Flex justify="space-between" align="center" px={8} key={variant.name}>
            <UnstyledButton
              p={4}
              fz={14}
              onClick={handleClickToGetVariant(variant.nanoId)}
              style={{ cursor: 'pointer', outlineOffset: '-2px' }}
            >
              {variant.name}
            </UnstyledButton>
          </Flex>
        ))}
      </ScrollArea.Autosize>
    </>
  );
};

export default ListVariant;

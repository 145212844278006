import { usePrevious } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import firstPageLoadHandling from './firstPageLoadHandling/firstPageLoadHandling';
import {
  PageSetContextData,
  PageSetContextType,
  PageSetVariantStateObj,
  VariantGenerationMessageCollection,
  VariantGenerationState,
  VariantPublishState,
} from './types';
import updatePageContentWithVariant from './updatePageContentWithVariant/updatePageContentWithVariant';
import useVariantGenerationWebSocket from './useVariantGenerationWebSocket/useVariantGenerationWebSocket';
import useVariantPublishWebSocket from './useVariantPublishWebSocket/useVariantPublishWebSocket';
import variantReducer from './variantStateReducer/variantStateReducer';

import { LandingpageDetails } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useCommonWebSocket from '~/hooks/useCommonWebSocket/useCommonWebSocket';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { publishPage, updatePublishSettings } from '~/services/PageServices';
import {
  createPageSet,
  createPageVariants,
  deletePageVariant,
  DeletePageVariantResponse,
  GetPageVariantData,
  getVariant,
  regenerateVariants,
  updatePageSet,
} from '~/services/PageSetServices/PageSetServices';

const PageSetContext = createContext<PageSetContextType>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const usePageSet = () => {
  const context = useContext(PageSetContext);
  if (!context) {
    throw new Error('usePageSet must be used within a PageSetProvider');
  }
  return context;
};

export const PageSetProvider = ({ children }: PropsWithChildren) => {
  const { send } = useCommonWebSocket<VariantGenerationMessageCollection>({
    filter: ({ data }) => data.includes('VARIANT_GENERATION'),
  });

  const [syncing, setSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [variantList, setVariantList] = useState<PageSetContextData>([]);
  const [variantState, dispatch] = useReducer(variantReducer, {});
  const [selectedVariant, setSelectedVariant] = useState<GetPageVariantData>();
  const [pageDetails, setPageDetails] = useState<Partial<LandingpageDetails>>({});
  const [blueprintEdited, setBluePrintEdited] = useState<Record<string, boolean>>();

  const prevSelectedVariant = usePrevious(selectedVariant);

  const { pageSetId, workspaceId, nanoId: pageNanoId } = pageDetails;

  const {
    processed: generated,
    toBeProcessedCount: toBeGeneratedCount,
    lastActionCompleteTimestamp: lastGenerationCompleteTimestamp,
    lastActionResult: lastGenerationResult,
    prepVariantActionBeforeInit: prepVariantGenerationBeforeInit,
    subscribeVariantMessages: subscribeVariantGenerationMessages,
    initVariantAction: initVariantGeneration,
    shouldContinueVariantAction: shouldContinueVariantGeneration,
    abruptResetDueToRouteChange,
  } = useVariantGenerationWebSocket({ pageSetId });

  const {
    processed: published,
    lastActionCompleteTimestamp: lastPublishCompleteTimestamp,
    lastActionResult: lastPublishResult,
    toBeProcessedCount: toBePublishedCount,
    initVariantAction: initVariantPublish,
    shouldContinueVariantAction: shouldContinueVariantPublish,
    subscribeVariantMessages: subscribeVariantPublishMessages,
    abruptResetDueToRouteChange: abruptPublishResetDueToRouteChange,
  } = useVariantPublishWebSocket({ pageSetId });

  const getVariantView = async (nanoId: string) => {
    setIsLoading(true);
    msg({ type: 'block-editor' });
    return await getVariant({ nanoId })
      .then((res) => {
        setSelectedVariant(res);
        return res;
      })
      .finally(() => {
        setIsLoading(false);
        msg({ type: 'unblock-editor' });
      });
  };

  const createVariantsPattern = async (
    pageSetNanoId: string,
    variantKeywords: string[],
    slugPattern?: string,
    titlePattern?: string,
  ) => {
    const { data: newPageVariantList } = await createPageVariants({
      pageSetId: pageSetNanoId,
      variantKeywords,
      slugPattern,
      titlePattern,
    });

    const generatedVariants: Record<string, VariantGenerationState> = {};

    newPageVariantList.forEach((variant) => {
      generatedVariants[variant.nanoId] = 'GENERATING';
    });

    initVariantGeneration(
      Object.keys(generatedVariants),
      variantState.generatedVariants,
      undefined,
      pageSetNanoId,
    );

    updatePageSet({
      nanoId: pageSetNanoId,
      state: {
        ...variantState,
        generatedVariants: {
          ...variantState.generatedVariants,
          ...generatedVariants,
        },
      },
    });

    await subscribeVariantGenerationMessages(newPageVariantList);
    setVariantList(newPageVariantList);
  };

  const addVariants = async (variantKeywords: string[]) => {
    if (!workspaceId || !pageNanoId || !pageSetId) return;
    prepVariantGenerationBeforeInit();
    const existingVariantKeywords: string[] = variantList.map((variant) => variant.name);
    createVariantsPattern(pageSetId, [...existingVariantKeywords, ...variantKeywords]);
  };

  const generateVariants = async (
    variantStrategy: string,
    variantKeywords: string[],
    urlSlug: string,
    title: string,
  ) => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    prepVariantGenerationBeforeInit();
    const { data: pageSetData } = await createPageSet({
      workspaceId,
      pageNanoId,
      variantStrategy,
    });

    try {
      await updatePublishSettings({
        nanoId: pageNanoId,
        workspaceId,
        title,
        urlSlug,
      });
    } catch (error) {
      let message = 'Unable to update page settings!';
      if (error instanceof Error && error.message) {
        message =
          typeof error.message == 'string' ? JSON.parse(error.message).message : error.message;
      }
      notifications.show({
        color: 'red',
        message,
        autoClose: 3000,
      });
    }

    setPageDetails({ ...pageDetails, pageSetId: pageSetData.nanoId });
    createVariantsPattern(pageSetData.nanoId, variantKeywords, urlSlug, title);
  };

  const publishVariants = async () => {
    if (!pageNanoId) return;
    setIsLoading(true);
    initVariantPublish(
      variantList.map((variant) => variant.nanoId),
      variantState.publishedVariants,
    );
    await subscribeVariantPublishMessages(variantList);

    await publishPage({
      nanoId: pageNanoId,
    });
    setIsLoading(false);
  };

  const regeneratePageVariant = async (nanoId: string) => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    initVariantGeneration([nanoId], variantState.generatedVariants);

    await subscribeVariantGenerationMessages(
      variantList.filter((variant) => variant.nanoId === nanoId),
    );
    await regenerateVariants({ pageId: pageNanoId, pageVariantId: nanoId });
    setIsLoading(false);
  };

  const regenerateAllPageVariants = async () => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    initVariantGeneration(
      variantList.map((variant) => variant.nanoId),
      variantState.generatedVariants,
    );
    setSyncing(true);
    await subscribeVariantGenerationMessages(variantList);
    await regenerateVariants({ pageId: pageNanoId, pageSetId });
    setIsLoading(false);
  };

  const clearBluePrintEdited = () => {
    dispatch({ type: 'reset-variant-state' });
    setBluePrintEdited({});
  };

  const resetPageSetContext = () => {
    setIsLoading(true);
    setVariantList([]);
    setPageDetails({});
    setSelectedVariant(undefined);
    clearBluePrintEdited();
    abruptResetDueToRouteChange();
    abruptPublishResetDueToRouteChange();
  };

  const updateVariantStateAndSwitchVariant = (
    pageSetNanoId: string,
    payload: PageSetVariantStateObj,
    switchVariant?: string,
  ) => {
    dispatch({ type: 'override-variant-state', payload });

    // Need to upload the state to PageSet object so it persist after browser refresh
    updatePageSet({
      nanoId: pageSetNanoId,
      state: payload,
    });
    if (switchVariant) {
      getVariantView(switchVariant).then((res) => {
        msg({ type: 'reset-bound' });
        document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
          updatePageContentWithVariant(
            viewer as HTMLIFrameElement,
            res.variantNodes,
            blueprintEdited,
          );
        });
      });
    }
  };

  const deleteVariant = async (nanoId: string) => {
    if (!nanoId) return;
    const response: DeletePageVariantResponse = await deletePageVariant({ nanoId });

    if (response.message === 'Page variant deleted successfully!') {
      const isLastVariant = variantList.length === 1;
      const newVariantList = variantList.filter((variant) => variant.nanoId !== nanoId);
      setVariantList(newVariantList);

      if (isLastVariant) {
        setSelectedVariant(undefined);
      } else if (selectedVariant?.nanoId === nanoId && pageSetId) {
        const newSelectedVariant = newVariantList[0];
        setSelectedVariant(newSelectedVariant);
        updateVariantStateAndSwitchVariant(
          pageSetId,
          { generatedVariants: lastGenerationResult as Record<string, VariantGenerationState> },
          newSelectedVariant.nanoId,
        );
      }

      notifications.show({
        color: 'green',
        message: 'Variant deleted successfully.',
        autoClose: 3000,
      });
    } else {
      notifications.show({
        color: 'red',
        message: 'Failed to delete the variant.',
        autoClose: 3000,
      });
    }
  };

  const traversePageDataForEditedState = () => {
    const allEditedElem =
      (
        document.querySelector('iframe[srcdoc]') as HTMLIFrameElement
      )?.contentDocument?.querySelectorAll<HTMLElement>('*[data-edited]') || [];

    const updateBluePrintTextEdit: Record<string, boolean> = {};
    [...allEditedElem].forEach((elem: HTMLElement) => {
      const { selector } = elem.dataset;
      if (selector) updateBluePrintTextEdit[selector] = true;
    });

    if (
      pageSetId &&
      variantState.editedVariant &&
      Object.keys(updateBluePrintTextEdit).length === 0
    ) {
      dispatch({
        type: 'set-selected-variant-is-edited',
        pageSetId,
        selectedVariantNanoId: undefined, // literally set this to undefined will remove this entry
      });
    }

    setBluePrintEdited(updateBluePrintTextEdit);
  };

  useViewerMessage(
    ({ data }) => {
      // 'first-fetch-page-data-completed' will determine whether this page is a page set or not
      // One cannot skip this process
      if (data.type === 'first-fetch-page-data-completed') {
        msg({ type: 'block-editor' });
        setIsLoading(true);
        setPageDetails(data.pageDetails);
        const { pageSetId: pId } = data.pageDetails;
        if (pId) {
          firstPageLoadHandling({
            pageDetails: data.pageDetails,
            shouldContinueVariantGeneration,
            shouldContinueVariantPublish,
            initVariantGeneration,
            initVariantPublish,
            updateVariantStateAndSwitchVariant,
            setVariantList,
            send,
          });
        } else {
          setIsLoading(false);
          msg({ type: 'unblock-editor' });
        }
      }

      // We also need to ensure the variant text are populated after page data state changes
      if (pageSetId && data.type === 'viewer-refreshed') {
        const viewer = document.querySelector(`iframe#${data.viewer}`);
        if (viewer && selectedVariant) {
          updatePageContentWithVariant(
            viewer as HTMLIFrameElement,
            selectedVariant.variantNodes,
            blueprintEdited,
          );
        }
      }

      // Full page reinit is triggered during Variant Generation Web Socket, it needs to preload the
      // first generated variant, only if is a new generation. Add more variant shouldn't trigger this.
      if (
        pageSetId &&
        data.type === 'full-page-reinit' &&
        variantList.length > 0 &&
        !selectedVariant
      ) {
        getVariantView(variantList[0].nanoId);
      }

      if (pageSetId && data.type === 'editing-performed' && data.outputData.blocks[0].data?.text) {
        setBluePrintEdited({
          ...blueprintEdited,
          [data.fromSelector || '']: true,
        });

        if (!variantState.editedVariant && selectedVariant) {
          dispatch({
            type: 'set-selected-variant-is-edited',
            pageSetId,
            selectedVariantNanoId: selectedVariant.nanoId,
          });
        }
      }

      if (
        pageSetId &&
        ['broadcast-history-change-page-data', 'update-page-content-success'].includes(data.type)
      ) {
        traversePageDataForEditedState();
      }
    },
    [selectedVariant?.nanoId, variantList, variantState, blueprintEdited, pageSetId],
  );

  /**
   * This caters only for switching between variants
   */
  useEffect(() => {
    if (selectedVariant && prevSelectedVariant?.nanoId !== selectedVariant?.nanoId) {
      document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
        updatePageContentWithVariant(
          viewer as HTMLIFrameElement,
          selectedVariant.variantNodes,
          blueprintEdited,
        );
      });
    }
  }, [selectedVariant?.nanoId, blueprintEdited]);

  const postGenerationProcess = () => {
    if (pageSetId) {
      // This should trigger getting the latest page content, which includes
      // variantNodeIds. This is so that the updatePageContentWithVariant can do its job
      msg({ type: 'full-page-reinit' });
      clearBluePrintEdited();

      notifications.show({
        color: 'green',
        message: `Your pages have been ${syncing ? 'synced' : 'generated'}`,
        autoClose: 3000,
      });

      setSyncing(false);

      // Need to upload the state to PageSet object so it persist after browser refresh
      updateVariantStateAndSwitchVariant(
        pageSetId,
        { generatedVariants: lastGenerationResult as Record<string, VariantGenerationState> },
        (selectedVariant || variantList[0])?.nanoId,
      );
    }
  };

  const postPublishProcess = () => {
    if (pageSetId) {
      // This should trigger getting the latest page content, which includes
      // variantNodeIds. This is so that the updatePageContentWithVariant can do its job
      msg({ type: 'full-page-reinit' });
      clearBluePrintEdited();

      msg({ type: 'show-modal', modal: 'publish-completed' });

      // Need to upload the state to PageSet object so it persist after browser refresh
      updateVariantStateAndSwitchVariant(
        pageSetId,
        { publishedVariants: lastPublishResult as Record<string, VariantPublishState> },
        (selectedVariant || variantList[0])?.nanoId,
      );
    }
  };

  useEffect(postGenerationProcess, [lastGenerationCompleteTimestamp]);
  useEffect(postPublishProcess, [lastPublishCompleteTimestamp]);

  return (
    <PageSetContext.Provider
      value={{
        generated,
        syncing,
        toBeGeneratedCount,
        isLoading,
        setIsLoading,
        pageSetId,
        variantList,
        selectedVariant,
        prevSelectedVariant,
        variantState,
        blueprintEdited,
        getVariantView,
        generateVariants,
        addVariants,
        regeneratePageVariant,
        regenerateAllPageVariants,
        deleteVariant,
        resetPageSetContext,
        clearBluePrintEdited,
        published,
        toBePublishedCount,
        publishVariants,
      }}
    >
      {children}
    </PageSetContext.Provider>
  );
};

import { Menu, Tooltip } from '@mantine/core';
import { IconAlignCenter, IconAlignLeft, IconAlignRight, IconMenu2 } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';

const iconProps = { size: 20, stroke: 1.5 };
const alignments = [
  {
    icon: <IconAlignLeft {...iconProps} />,
    value: 'left',
  },
  {
    icon: <IconAlignCenter {...iconProps} />,
    value: 'center',
  },
  {
    icon: <IconAlignRight {...iconProps} />,
    value: 'right',
  },
  {
    icon: <IconMenu2 {...iconProps} />,
    value: 'justify',
  },
];

type TextAlignToolProps = {
  handleClickToEmitEvent: (key: string, value: string) => void;
  viewerData: Partial<ViewerData>;
};

const TextAlignTool = ({ handleClickToEmitEvent, viewerData }: TextAlignToolProps) => {
  const [alignmentValue, setAlignmentValue] = useState<string>('left');

  useEffect(() => {
    setAlignmentValue(viewerData?.editorState?.textAlign || 'left');
  }, [viewerData]);

  let iconComponent;

  switch (alignmentValue) {
    case 'left':
      iconComponent = <IconAlignLeft {...iconProps} />;
      break;
    case 'center':
      iconComponent = <IconAlignCenter {...iconProps} />;
      break;
    case 'right':
      iconComponent = <IconAlignRight {...iconProps} />;
      break;
    case 'justify':
      iconComponent = <IconMenu2 {...iconProps} />;
      break;
    default:
      iconComponent = <IconAlignLeft {...iconProps} />;
  }

  return (
    <Menu aria-label="text-align-tool" position="bottom" zIndex={1001}>
      <Tooltip label="Alignment" color="Gray" withArrow zIndex={1001}>
        <Menu.Target>
          <StyledActionIcon variant="transparent">{iconComponent}</StyledActionIcon>
        </Menu.Target>
      </Tooltip>
      <Menu.Dropdown>
        <Menu.Label>Alignment</Menu.Label>
        {alignments.map((alignment) => (
          <Menu.Item
            aria-label={`text-align-tool-${alignment.value}`}
            key={alignment.value}
            onClick={() => {
              setAlignmentValue(alignment.value);
              handleClickToEmitEvent('alignment', alignment.value);
            }}
          >
            {alignment.icon}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default TextAlignTool;

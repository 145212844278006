import awsAPIClient from '~/helpers/ApiClient/ApiClient';

interface GetCodeEmbedResponse {
  message: string;
  data: {
    fileContent: string;
  };
}

interface CreateCodeEmbedResponse {
  message: string;
  url: string;
}

export const getCodeEmbed = async ({
  fileName,
  workspaceId,
}: {
  fileName: string;
  workspaceId: string;
}) => {
  return awsAPIClient.post<GetCodeEmbedResponse>('/code-embed', {
    fileName,
    workspaceId,
  });
};

export const createCodeEmbed = async ({
  workspaceId,
  pageId,
  content,
}: {
  workspaceId: string;
  pageId: string;
  content: string;
}) => {
  return awsAPIClient.post<CreateCodeEmbedResponse>('/create-code-embed', {
    workspaceId,
    pageId,
    content,
  });
};

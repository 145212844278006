import { Tooltip } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import { useDrag } from 'react-dnd';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ContainsNode, ViewerData } from '~/global.types';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';

const iconProps = { size: 20, stroke: 1.5 };

type MovingToolProps = {
  viewerData: Partial<ViewerData>;
};

const MovingTool = ({ viewerData }: MovingToolProps) => {
  const dataItem: ContainsNode = {
    selector: viewerData.elementSelector || '',
  };

  const [{ cursor }, dragRef] = useDrag(
    () => ({
      type: 'move-page-element',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [viewerData],
  );

  return (
    <>
      <Tooltip label="Move" color="Gray" withArrow zIndex={1001}>
        <StyledActionIcon
          ref={dragRef}
          style={{
            cursor,
          }}
          variant="transparent"
        >
          <IconGripVertical {...iconProps} />
        </StyledActionIcon>
      </Tooltip>
    </>
  );
};

export default MovingTool;

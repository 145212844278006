import { Button, FileButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import {
  IMAGE_PER_BATCH,
  IMAGE_SIZE_LIMIT,
  MAX_IMAGE_COUNT,
  SUPPORTED_IMAGE_TYPES,
} from '~/constants';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { MediaCollectionItem, useMediaCollection } from '~/providers/MediaCollectionProvider';
import { uploadImages } from '~/services/AssetServices';

interface ImageUploaderProps {
  colors?: string;
  opacity?: number;
  customText?: string;
  onFileSelected?: (image: File | null) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  colors,
  opacity,
  customText,
  onFileSelected,
}) => {
  const [, [workspaceId], [imageList, setImageList]] = useMediaCollection();
  const [files, setFiles] = useState<File[] | null>(null);
  const [loader, setLoader] = useState(false);

  const addImagesToList = (dataItems: MediaCollectionItem[]) => {
    setImageList([...dataItems, ...imageList]);
  };

  const bulkUploadFiles = async (selectedImages: File[] | File | null) => {
    if (selectedImages === null) return;
    const images = Array.isArray(selectedImages) ? selectedImages : [selectedImages];

    if (images.length > MAX_IMAGE_COUNT) {
      notifications.show({
        color: 'red',
        message: `Cannot upload ${images.length} images. The total number of images cannot exceed ${MAX_IMAGE_COUNT}.`,
        autoClose: 3000,
      });
      return;
    }

    const validFiles = images.filter((image) => {
      if (image.size > IMAGE_SIZE_LIMIT) {
        notifications.show({
          color: 'red',
          message: `The file ${image.name} is too large. Skipping upload.`,
          autoClose: 3000,
        });
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    setFiles(validFiles);

    setLoader(true);

    try {
      if (onFileSelected) onFileSelected(validFiles[0]);

      const fileChunks: File[][] = [];
      for (let i = 0; i < validFiles.length; i += IMAGE_PER_BATCH) {
        fileChunks.push(validFiles.slice(i, i + IMAGE_PER_BATCH));
      }

      const uploadImagesPromises = fileChunks.map((fileChunk) =>
        uploadImages({ workspaceId, files: fileChunk }).then(({ images_urls }) =>
          images_urls.map((image, index) => ({
            tagName: 'img',
            '@src': image.thumbnail,
            '@alt': fileChunk[index].name,
          })),
        ),
      );

      const allImagesItems = await Promise.all(uploadImagesPromises);

      addImagesToList(allImagesItems.flat());
    } catch (error) {
      notifications.show({
        id: 'image-upload-error',
        color: 'red',
        message: `Error uploading image${validFiles.length > 1 ? 's' : ''}. Please try again.`,
        autoClose: 3000,
      });
    } finally {
      setFiles(null);
      setLoader(false);
    }
  };

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'upload-to-collection') {
        bulkUploadFiles([data.file]);
      }
    },
    [workspaceId, addImagesToList],
  );

  return (
    <>
      <FileButton
        onChange={bulkUploadFiles}
        accept={SUPPORTED_IMAGE_TYPES.join(',')}
        multiple={!onFileSelected}
      >
        {(props) => (
          <Button
            {...props}
            fullWidth
            loading={loader}
            data-testid="image-upload"
            mb="sm"
            color={colors}
            opacity={opacity}
            style={{ border: '1px solid #ffffff' }}
          >
            {loader
              ? `Uploading ${files?.length} file${files?.length && files.length > 1 ? 's' : ''}...`
              : customText || 'Upload Images'}
          </Button>
        )}
      </FileButton>
    </>
  );
};

export default ImageUploader;

import { FileWithPath } from '@mantine/dropzone';
import { encode } from 'base64-arraybuffer';

import awsAPIClient from '~/helpers/ApiClient/ApiClient';

interface UpdatePropertyObject {
  workspaceId: string;
  file: FileWithPath[];
}

interface ImageVersions {
  originalImage?: string;
  thumbnail?: string;
}

interface ImageMeta {
  lastModified: number;
  altText: string;
}

type Image = ImageVersions & ImageMeta;

interface UploadImageObject {
  workspaceId: string;
  files: File[];
}

export interface FetchMediaCollectionProps {
  workspaceId: string;
  page?: number;
  perPage?: number;
  thumbnailOnly?: boolean;
  originalImageOnly?: boolean;
  orderByLastModified?: string;
  archived?: boolean;
}

export interface TransferAssetResponse {
  fileName: string;
  filePaths: string[];
  metadata?: Record<string, string>;
}

export interface MediaCollection {
  images: Image[];
  total: number;
  page: number;
  perPage: number;
}

export interface UploadImageResponse {
  images_urls: ImageVersions[];
  failed_images: string[];
}

export const uploadFile = async ({ workspaceId, file }: UpdatePropertyObject) => {
  // TODO: In current use case, we only need to upload one file at a time. Future
  // is to achieve bulk file uploads.
  const bufferData = encode(await file[0].arrayBuffer());

  return awsAPIClient.put<TransferAssetResponse>('/assets-handling', {
    workspaceNanoId: workspaceId,
    bufferData,
    fileName: file[0].name,
    type: file[0].type,
  });
};

export const uploadImages = async ({ workspaceId, files }: UploadImageObject) => {
  const images = await Promise.all(
    files.map(async (image) => {
      const bufferData = encode(await image.arrayBuffer());

      const fileNameParts = image.name.split('.');
      const extension = fileNameParts.pop()?.toLowerCase();
      const baseFileName = fileNameParts.join('.');

      return {
        bufferData,
        fileName: `${baseFileName}.${extension}`,
        type: image.type,
      };
    }),
  );

  return awsAPIClient.post<UploadImageResponse>('/upload-images', {
    workspaceId,
    images,
  });
};

export const fetchMediaCollection = async ({
  workspaceId,
  page = 1,
  perPage = 5,
  thumbnailOnly = true,
  originalImageOnly = false,
  orderByLastModified = 'ASC',
  archived = false,
}: FetchMediaCollectionProps) => {
  return awsAPIClient.post<MediaCollection>('/get-media-collection', {
    workspaceId,
    page,
    perPage,
    archived,
    thumbnailOnly,
    originalImageOnly,
    orderByLastModified,
  });
};

export const transferAsset = async (workspaceId: string, url: string) => {
  const path = url.split(`${workspaceId}/`)[1].replace('/thumbnail/', '/original/');
  return awsAPIClient.put<TransferAssetResponse>('/transfer-asset', {
    workspaceId,
    path,
  });
};

export const deleteImages = async (workspaceId: string, url: string) => {
  const fileName = url.split('/').pop();
  return awsAPIClient.post('/delete-image-workspace', {
    workspaceId,
    fileName,
  });
};

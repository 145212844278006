import Userfront from '@userfront/toolkit/react';

Userfront.init(import.meta.env?.VITE_USERFRONT_TENANT_ID ?? '9nymm5yn');

class ApiClient {
  private baseUrl: string;
  private customHeaders: Record<string, string>;

  private static instance: ApiClient;

  private constructor(baseUrl: string, headers: Record<string, string> = {}) {
    this.baseUrl = baseUrl;
    this.customHeaders = headers;
  }

  static getInstance(baseUrl: string, headers: Record<string, string> = {}): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(baseUrl, headers);
    }
    return ApiClient.instance;
  }

  setCustomHeaders(customHeaders: Record<string, string>) {
    this.customHeaders = { ...this.customHeaders, ...customHeaders };
  }

  private async fetchWithAuthorization(
    url: string,
    method: string,
    data?: Record<string, unknown>,
  ): Promise<Response> {
    let jwtToken = Userfront.tokens.accessToken;

    if (!jwtToken) {
      await Userfront.tokens.refresh();
      jwtToken = Userfront.tokens.accessToken;
      console.log('Refreshing token');
    }

    const headersWithAuth = {
      Authorization: jwtToken ? `Bearer ${jwtToken}` : 'No Token',
      'Content-Type': 'application/json',
      ...this.customHeaders,
    };

    const options: RequestInit = {
      method,
      headers: headersWithAuth,
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    return fetch(`${this.baseUrl}${url}`, options);
  }

  async get<T>(url: string): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'GET');
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
  }

  async post<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'POST', data);
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      if (contentType?.includes('application/json')) {
        return response.json() as unknown as T;
      }
      if (contentType?.includes('text/csv')) {
        return response.blob() as unknown as T;
      } else {
        throw new Error(`Unsupported content type: ${contentType}`);
      }
    } else {
      throw response;
    }
  }

  async put<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'PUT', data);
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(JSON.stringify(await response.json()));
    }
  }

  async delete<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'DELETE', data);
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(`Failed to delete data: ${response.statusText}`);
    }
  }
}

// Singleton instance of ApiClient
const baseUrl = import.meta.env?.VITE_AWS_API_BASE_URL ?? 'https://api.example.com';

const awsAPIClient = ApiClient.getInstance(baseUrl, {});

export default awsAPIClient;

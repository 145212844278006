import { MutationBehavior } from 'use-undoable';

import addPageData from './pageProcessing/addPageData/addPageData';
import addSection from './pageProcessing/addSection/addSection';
import { duplicateElement } from './pageProcessing/duplicateElement/duplicateElement';
import imageSwapUploadState from './pageProcessing/imageSwapUploadState/imageSwapUploadState';
import { imageAttachState } from './pageProcessing/imageUploadAndAttach/imageUploadAndAttach';
import modifyPageData from './pageProcessing/modifyPageData/modifyPageData';
import placeholderToImage from './pageProcessing/placeholderToImage/placeholderToImage';
import removeIsEditedProperty from './pageProcessing/removeIsEditedProperty/removeIsEditedProperty';
import removeAllUploadState from './pageProcessing/removeUploadState/removeUploadState';

import { LandingpageDetails, PageJsonSnippetObj } from '~/global.types';
import { findPageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import msg from '~/helpers/viewerInteractions/msg';
import { StudioMessageCollection } from '~/messages.types';

let cachedPageDataForSaveLater: string | undefined;
let cachedAutoSelectAfterAddElement:
  | undefined
  | {
      viewer?: string;
      elementSelector?: string;
    };

type AppMessageHandlingDataParser = [
  string,
  (
    payload: string | ((oldValue: string) => string),
    behavior?: MutationBehavior | undefined,
    ignoreAction?: boolean | undefined,
  ) => void,
  LandingpageDetails,
  React.Dispatch<React.SetStateAction<LandingpageDetails>>,
  string[],
  React.Dispatch<React.SetStateAction<number>>,
  React.Dispatch<React.SetStateAction<boolean>>,
];

const AppMessageHandling = (
  { data }: MessageEvent<StudioMessageCollection>,
  [
    pageData,
    setPageData,
    pageDetails,
    setPageDetails,
    past,
    triggerInitialise,
    setIsBriefCompleted,
  ]: AppMessageHandlingDataParser,
) => {
  const { type } = data;

  // This is to cater if edit process is not completed but back button is triggered
  // edit sync message cannot fully process upon unmount, so need to clear the editing cache
  // when first-fetch happens
  if (type === 'first-fetch-page-data-completed') {
    cachedPageDataForSaveLater = undefined;
  }

  if (type === 'trigger-broadcast-page-data') {
    msg({ type: 'broadcast-page-data', pageData, pageDetails });
  }

  if (type === 'trigger-broadcast-inline-edit-page-data') {
    const pageDataToBeSend = cachedPageDataForSaveLater || pageData;
    msg({ type: 'broadcast-inline-edit-page-data', pageData: pageDataToBeSend, pageDetails });
  }

  if (type === 'head-edited') {
    const modifiedDataObject = {
      ...JSON.parse(pageData),
      head: data.head,
    };
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'editing-performed') {
    const blockData = data.outputData.blocks[0];

    if (blockData?.data && pageDetails.pageSetId) {
      blockData.data.edited = true;
    }

    const modifiedDataObject = modifyPageData(
      JSON.parse(cachedPageDataForSaveLater || pageData),
      blockData || { id: data.fromSelector, toDelete: true },
    );

    cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
    msg({ type: 'update-bound' });
  }

  if (type === 'editing-performed-from-panel') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), data.outputData);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'editing-publish-settings') {
    setPageData(
      JSON.stringify(
        {
          ...JSON.parse(pageData),
          googleTagManagerId: data.outputData.googleTagManagerId || '',
          description: data.outputData.description || '',
          title: data.outputData.title || '',
          favicon: data.outputData.favicon || '',
        },
        null,
        2,
      ),
    );
    setPageDetails({
      ...pageDetails,
      definition: data.outputData.urlSlug || '',
      // Investigate whether we can now remove these
      gtmId: data.outputData.googleTagManagerId || '',
      description: data.outputData.description || '',
      title: data.outputData.title || '',
      favicon: data.outputData.favicon || '',
    });
  }

  if (type === 'editing-publish-settings-variant') {
    // if the page is a page set, then we need to update the favicon only because other settings are variant specific
    setPageData(
      JSON.stringify(
        {
          ...JSON.parse(pageData),
          favicon: data.outputData.favicon,
          googleTagManagerId: data.outputData.googleTagManagerId,
        },
        null,
        2,
      ),
    );
    setPageDetails({
      ...pageDetails,
      favicon: data.outputData.favicon || '',
      gtmId: data.outputData.googleTagManagerId || '',
    });
    msg({ type: 'editing-publish-settings-success' });
  }

  // Another converaging target. Need to test it whether we need this anymore
  if (type === 'editor-sync' && cachedPageDataForSaveLater) {
    setPageData(cachedPageDataForSaveLater);
    cachedPageDataForSaveLater = undefined;
  }

  if (type === 'dev-editor-sync') {
    setPageData(data.htmlStr as string);
    msg({ type: 'update-bound' });
  }

  if (type === 'delete-page-elements') {
    let modifiedDataObject = JSON.parse(pageData);
    data.delete.forEach((del: string) => {
      modifiedDataObject = modifyPageData(modifiedDataObject, {
        id: del,
        toDelete: true,
      });
    });
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    cachedPageDataForSaveLater = undefined;
  }

  if (type === 'add-page-element') {
    const { result, addedElementSelector } = addPageData(JSON.parse(pageData), data, true);
    setPageData(JSON.stringify(result, null, 2));

    cachedAutoSelectAfterAddElement = {
      viewer: data.messageData.data.viewer,
      elementSelector: addedElementSelector as string,
    };
  }

  if (type === 'duplicate-page-element') {
    const modifiedDataObject = duplicateElement(pageData, data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  // Once viewer is refreshed and image fully loaded, if the most recent is adding a text-unit
  // or button-unit we need to be able to focus on that, so that user can start typing immediately
  if (type === 'image-fully-loaded' && cachedAutoSelectAfterAddElement?.viewer === data.viewer) {
    const { viewer, elementSelector } = cachedAutoSelectAfterAddElement || {};
    const viewerElem = document.querySelector(`#${viewer}`);

    if (!(viewerElem instanceof HTMLIFrameElement)) {
      return console.error('Missing viewer');
    }

    const viewerContentDoc = viewerElem.contentDocument;

    setTimeout(() => {
      const selectElement = viewerContentDoc?.querySelector(`[data-selector=${elementSelector}]`);

      if (selectElement) {
        selectElement.dispatchEvent(new MouseEvent('mousedown'));
        // EditorJS actually has a timeout while initialising. Thus, before focusing is triggered
        // this needs to be waited.
        setTimeout(() => {
          (viewerContentDoc?.querySelector('.upf-unit-edit') as HTMLElement)?.focus();
          cachedAutoSelectAfterAddElement = undefined;
        }, 100);
      }
    }, 150);
  }

  if (type === 'move-page-element') {
    const modifiedDataObject = addPageData(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'add-section') {
    const modifiedDataObject = addSection(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'move-element-in-new-section') {
    const addNode = findPageData(JSON.parse(pageData), data.selector);
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      toDelete: true,
    });

    const result = addSection(modifiedDataObject, {
      type: 'add-section',
      messageData: data.messageData,
      containsToBeAdded: [
        {
          ...addNode,
          '@slot': 'column-1',
        } as PageJsonSnippetObj,
      ],
    });
    setPageData(JSON.stringify(result, null, 2));
  }

  if (type === 'move-page-section') {
    const objectToBeAdded = findPageData(JSON.parse(pageData), data.selector);
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      toDelete: true,
    });
    const result = addSection(modifiedDataObject, {
      type: 'add-section',
      messageData: { elementSelector: data.destination },
      objectToBeAdded,
    });
    setPageData(JSON.stringify(result, null, 2));
  }

  if (type === 'get-selected-page-data') {
    const { elementSelector, messageData, openToolbarPanel, openToolbarSubPanel } = data;
    const result = findPageData(JSON.parse(pageData), elementSelector);
    msg({
      type: 'receive-selected-page-data',
      result,
      openToolbarPanel,
      openToolbarSubPanel,
      viewerData: messageData.data,
    });
  }

  if (type === 'replace-styles') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'add-image-placeholder-while-uploading') {
    const modifiedDataObject = addPageData(JSON.parse(pageData), data);

    if (data.addNewCol) {
      setPageData(JSON.stringify(modifiedDataObject, null, 2));
    } else {
      imageAttachState(data);
      cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
    }
  }

  if (type === 'replace-and-upload-image') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      data: {
        dataImageSrc: data.imageSrc,
      },
    });
    imageSwapUploadState(data.selector);
    cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
  }

  if (type === 'image-upload-complete') {
    const modifiedDataObject = placeholderToImage(
      JSON.parse(cachedPageDataForSaveLater || pageData),
      data.uploaded,
    );

    if (cachedPageDataForSaveLater) {
      setPageData(JSON.stringify(modifiedDataObject, null, 2));
      cachedPageDataForSaveLater = undefined;
    } else {
      setPageData(JSON.stringify(modifiedDataObject, null, 2), undefined, true);
    }
  }

  if (type === 'restore-page-when-error') {
    if (cachedPageDataForSaveLater) {
      cachedPageDataForSaveLater = undefined;
      removeAllUploadState();
    } else {
      // Drop to add image to new section or new column hasn't converted to use cachedPageDataForSaveLater yet
      setPageData(past[past.length - 1], undefined, true);
    }
  }

  if (type === 'full-page-reinit') {
    triggerInitialise(new Date().valueOf());
  }

  if (type === 'complete-initial-brief') {
    setIsBriefCompleted(true);
  }

  if (type === 'before-regenerating-variants') {
    const modifiedDataObject = removeIsEditedProperty(JSON.parse(pageData));
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'meaning-unit-dimension-changed') {
    msg({ type: 'update-bound' });
  }
};

export default AppMessageHandling;

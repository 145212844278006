import ShortUniqueId from 'short-unique-id';

import { PageJsonSnippetObj } from '~/global.types';
import { extractTextContentFromElement } from '~/helpers/viewerInteractions/extractTextContentFromElement';
import { getElementFromViewer } from '~/helpers/viewerInteractions/getElementFromViewer';
import { StudioMessage } from '~/messages.types';

const { randomUUID } = new ShortUniqueId();

function isTextOrButton(key: string, obj: PageJsonSnippetObj): boolean {
  return key === 'type' && (obj[key] === 'text' || obj[key] === 'button');
}

function replaceSelectorsAndRemoveVariantNodeId(
  obj: PageJsonSnippetObj,
  viewer?: string,
  isFirstLevel: boolean = true,
  shouldRemoveVariantNodeId: boolean = false,
): PageJsonSnippetObj {
  const hasVariantNodeId = 'variantNodeId' in obj;

  if (hasVariantNodeId && shouldRemoveVariantNodeId) {
    const selector = obj['selector'] as string;
    const element = getElementFromViewer(viewer, selector);
    if (element) {
      obj['textValue'] = extractTextContentFromElement(element);
      delete obj['variantNodeId'];
    }
  }

  for (const key in obj) {
    if (key === 'selector') {
      obj[key] = isFirstLevel ? `layout-${randomUUID(8)}` : `elem-${randomUUID(8)}`;
    } else if (isTextOrButton(key, obj)) {
      obj['@data-edited'] = true;
    } else if (typeof obj[key] === 'object') {
      replaceSelectorsAndRemoveVariantNodeId(
        obj[key] as PageJsonSnippetObj,
        viewer,
        false,
        shouldRemoveVariantNodeId,
      );
    }
  }

  return obj;
}

function addSection(
  obj: PageJsonSnippetObj,
  {
    messageData,
    containsToBeAdded,
    objectToBeAdded,
    removeVariantNodeId,
  }: StudioMessage['AddSection'],
) {
  const updatedObject = JSON.parse(JSON.stringify(obj));

  const { contains } = updatedObject;

  const sectionLocation = contains.findIndex(
    (item: PageJsonSnippetObj) => item.selector === messageData.elementSelector,
  );

  if (!sectionLocation)
    contains.findIndex((item: PageJsonSnippetObj) => item.category === messageData.id);

  contains.splice(
    sectionLocation + 1,
    0,
    objectToBeAdded
      ? replaceSelectorsAndRemoveVariantNodeId(
          objectToBeAdded,
          messageData.viewer,
          true,
          removeVariantNodeId,
        )
      : {
          contains: containsToBeAdded,
          selector: `layout-${randomUUID(8)}`,
          layoutName: 'column-layout',
          style: {
            padding: '48px 0',
          },
        },
  );

  return updatedObject;
}

export default addSection;

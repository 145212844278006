import { Tooltip } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

interface DuplicateToolProps {
  viewerData: Partial<ViewerData>;
  isSection?: boolean;
}

const DuplicateTool = ({ viewerData }: DuplicateToolProps) => {
  const handleClickToEmitInlineToolEvent = () => {
    msg({
      type: 'duplicate-page-element',
      messageData: { data: viewerData },
    });
  };

  return (
    <Tooltip label="Duplicate" color="gray" withArrow zIndex={1001}>
      <StyledActionIcon
        aria-label="duplicate-tool"
        variant="transparent"
        onClick={handleClickToEmitInlineToolEvent}
      >
        <IconCopy size={20} stroke={1.5} />
      </StyledActionIcon>
    </Tooltip>
  );
};

export default DuplicateTool;

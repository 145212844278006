import { Badge, Box, Button, FileButton, Flex, Group, List, Loader, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconFileUpload } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

import { validateCSVFile } from './validateCSVFile';

import BasePanel from '../BasePanel/BasePanel';

import { ToolbarPanelProps } from '~/global.types';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { createDataSet, getDataSet } from '~/services/DataSetServices';

const AddDataSetPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  const [loading, setLoading] = useState(false);
  const { pageSetId, selectedVariant } = usePageSet();
  const [datasetAlreadyCreated, setDatasetAlreadyCreated] = useState(false);
  const resetRef = useRef<() => void>(null);

  useEffect(() => {
    if (selectedVariant?.externalData) {
      setDatasetAlreadyCreated(true);
    }
  }, [selectedVariant, loading]);

  const handleDownloadTemplate = async () => {
    if (pageSetId) {
      const datasetBlob = await getDataSet({ pageSetId });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(datasetBlob);
      link.download = datasetAlreadyCreated ? 'dataset.csv' : 'template.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const handleUploadCSV = async (file: File) => {
    try {
      if (pageSetId) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            setLoading(true);
            const base64Data = reader.result as string;
            const csvData = {
              bufferData: base64Data.split(',')[1],
              fileName: file.name,
            };
            await createDataSet({ pageSetId, csvData });
            setDatasetAlreadyCreated(true);
            notifications.show({
              color: 'green',
              message: `${file.name} has been uploaded successfully.`,
              autoClose: 3000,
            });
          } catch (error) {
            notifications.show({
              color: 'red',
              message: `${file.name} failed to upload.`,
              autoClose: 3000,
            });
          } finally {
            setLoading(false);
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      setLoading(false);
      notifications.show({
        color: 'red',
        message: `${file.name} failed to upload.`,
        autoClose: 3000,
      });
    }
  };

  const handleFileButtonChange = (file: File | null) => {
    if (!file) return;
    validateCSVFile(file, handleUploadCSV);
    resetRef.current?.();
  };

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-dataset')}
      label="Add dataset options"
      title="Dataset"
      icon={<IconFileUpload />}
      publishButton={
        <Box p={24} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
          <FileButton
            onChange={handleFileButtonChange}
            accept="text/csv"
            disabled={loading}
            resetRef={resetRef}
          >
            {(props) => (
              <Button w="100%" size="md" fw={400} {...props} disabled={loading}>
                {loading ? (
                  <Group gap="10px">
                    <Loader size="sm" color="#ADB5BD" />
                    <Text>Importing CSV file...</Text>
                  </Group>
                ) : datasetAlreadyCreated ? (
                  'Re-import CSV file'
                ) : (
                  'Import CSV file'
                )}
              </Button>
            )}
          </FileButton>
        </Box>
      }
    >
      {datasetAlreadyCreated ? (
        <Flex direction="column" gap={24} w="100%">
          <Flex direction="column">
            <Flex justify="space-between" align="center" w="100%">
              <Text size="sm" mt="md" fw="500">
                Ingestion status
              </Text>
              <Badge mt="md" color="#ebfbee" size="xs">
                <Text size="10" fw="600" c="#2b8a3e">
                  ● COMPLETE
                </Text>
              </Badge>
            </Flex>
            <Text
              c="#5a1cec"
              size="sm"
              style={{
                cursor: 'pointer',
              }}
              onClick={handleDownloadTemplate}
            >
              {'dataset.csv'}
            </Text>
          </Flex>
          <Text size="sm" fw={400} c="#495057">
            Keep your external data up to date by re-importing the latest CSV file.
          </Text>
        </Flex>
      ) : (
        <>
          <Text mt={24} fw={400} size="sm" c="#495057">
            Use external data to customize content for each variant.
          </Text>
          <List
            type="ordered"
            mt="sm"
            fw={400}
            size="sm"
            spacing="xs"
            c="#495057"
            pr="16px"
            pl="4px"
          >
            <List.Item>
              Import data as a CSV file, with keywords in column 1. To get started, download
              <Text
                onClick={handleDownloadTemplate}
                size="sm"
                style={{ color: '#5A1CEC', cursor: 'pointer', display: 'inline' }}
              >
                {' '}
                template.csv
              </Text>
              .
            </List.Item>
            <List.Item>
              Map the CSV data to your page’s Text, Images and Buttons by using the format{' '}
              {'{{Column_Name}}'}.
            </List.Item>
            <List.Item>Go to Preview to see data rendered inside your pages.</List.Item>
          </List>
        </>
      )}
    </BasePanel>
  );
};

export default AddDataSetPanel;

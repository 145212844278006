import { Box, Button, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft } from '@tabler/icons-react';
import { Key, useEffect, useState } from 'react';

import { validateCodeEmbed } from './validateCodeEmbed';

import BasePanel from '../BasePanel/BasePanel';

import { ToolbarPanelProps } from '~/global.types';
import { getPageIdFromPathName } from '~/helpers/getPageIdFromPathName/getPageIdFromPathName';
import msg from '~/helpers/viewerInteractions/msg';
import { BlockOutput } from '~/messages.types';
import { useStudioWorkspace } from '~/providers/WorkspaceProvider/WorkspaceProvider';
import { createCodeEmbed, getCodeEmbed } from '~/services/CodeEmbedServices';

const CodeEmbedPanel = ({
  opened,
  handleClickToToggleSubPanel,
  dataFromPostMessage = {},
}: ToolbarPanelProps) => {
  const [{ workspaceId }] = useStudioWorkspace();
  const [saving, setSaving] = useState(false);
  const [loadCodeEmbed, setLoadCodeEmbed] = useState(false);

  const form = useForm({
    initialValues: {
      code: '',
      workspaceId: '',
      pageId: '',
    },
    validateInputOnChange: true,
    validate: {
      code: validateCodeEmbed,
    },
  });

  useEffect(() => {
    const openedPanelIsCodeEmbed =
      opened && workspaceId && dataFromPostMessage?.tagName === 'code-embed';

    // We have yet to cater if there is no fileName property
    // don't call this - most likely we just need to add one more condition above
    if (openedPanelIsCodeEmbed) {
      form.reset();
      const pageId = getPageIdFromPathName() ?? '';
      form.setValues({ workspaceId, pageId });

      if (typeof dataFromPostMessage.fileName === 'string') {
        setLoadCodeEmbed(true);

        getCodeEmbed({
          fileName: dataFromPostMessage.fileName,
          workspaceId,
        })
          .then((getCodeEmbedResult) => {
            if (getCodeEmbedResult.data.fileContent) {
              form.setValues({ code: getCodeEmbedResult.data.fileContent });
            }
            setLoadCodeEmbed(false);
          })
          .catch(() => {
            notifications.show({
              color: 'red',
              message: 'Error getting code embed',
              autoClose: 3000,
            });
            setLoadCodeEmbed(false);
          });
      }
    }
  }, [opened, workspaceId, dataFromPostMessage?.selector]);

  const handleSave = async () => {
    setSaving(true);

    if (opened && form.isDirty()) {
      try {
        const response = await createCodeEmbed({
          content: form.values.code,
          workspaceId: form.values.workspaceId,
          pageId: form.values.pageId,
        });
        const outputData: BlockOutput = {
          id: dataFromPostMessage.selector as string,
          jsonOverride: {
            tagName: 'code-embed',
            fileName: response.url,
          },
        };
        msg({ type: 'editing-performed-from-panel', outputData });
        notifications.show({
          color: 'blue',
          message: 'Code embed saved successfully!',
          autoClose: 3000,
        });
        setSaving(false);
      } catch (error) {
        notifications.show({
          color: 'red',
          message: 'Error saving code embed',
          autoClose: 3000,
        });
        setSaving(false);
      }
    }
  };

  return (
    <BasePanel
      key={dataFromPostMessage?.selector as Key}
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('code-embed-panel')}
      label="Code Embed Panel"
      title="Configure Code Embed"
      publishButton={
        <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
          <Button
            w="100%"
            size="md"
            disabled={!form.isValid()}
            loading={saving}
            loaderProps={{ type: 'dots' }}
            onClick={handleSave}
            fw={400}
          >
            Save
          </Button>
        </Box>
      }
      icon={
        <IconArrowLeft
          cursor={'pointer'}
          onClick={() =>
            msg({
              type: 'open-toolbar-panel',
              openToolbarPanel: 'add-element',
              openToolbarSubPanel: 'add-element',
            })
          }
          aria-label='Back to "Add Element" panel'
        />
      }
    >
      <Stack gap={4}>
        <Textarea
          rows={8}
          label="Embed code"
          description="Copy the HTML code snippet you want to add and paste it into the box below"
          styles={{
            input: {
              fontSize: 12,
              color: 'var(--mantine-color-dark-7)',
            },
            description: {
              fontSize: 14,
              color: 'var(--mantine-color-gray-7)',
            },
            label: {
              fontWeight: 600,
              color: 'var(--mantine-color-dark-7)',
            },
          }}
          placeholder="<iframe src=”https://myiframe.com/” .... >"
          disabled={loadCodeEmbed}
          {...form.getInputProps('code')}
        />

        <Text fz={12} c="var(--mantine-color-gray-7)">
          Use Preview to check how your embedded code looks after hitting Save.
        </Text>
      </Stack>
    </BasePanel>
  );
};

export default CodeEmbedPanel;

import addSection from '../addSection/addSection';

import { PageJsonSnippetObj } from '~/global.types';
import { findPageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import { extractTextContentFromElement } from '~/helpers/viewerInteractions/extractTextContentFromElement';
import { getElementFromViewer } from '~/helpers/viewerInteractions/getElementFromViewer';
import { StudioMessage } from '~/messages.types';
import addPageData from '~/screens/Studio/pageProcessing/addPageData/addPageData';

function processElementNodeWithText(
  addNode: PageJsonSnippetObj,
  element: HTMLElement,
): PageJsonSnippetObj {
  const textValue = extractTextContentFromElement(element);
  return {
    ...addNode,
    '@data-edited': true,
    variantNodeId: undefined, // Remove the existing variant ID
    textValue, // Update the text value to not show the blueprint text
  };
}

const forUnit = (pageData: string, data: StudioMessage['DuplicatePageElement']) => {
  const parsedPageData = JSON.parse(pageData);
  const { viewer, elementSelector } = data.messageData.data;
  const addNode = findPageData(parsedPageData, elementSelector);
  const element = getElementFromViewer(viewer, elementSelector);

  if (element) {
    data.messageData.data.rootNodeHost = element.parentElement?.dataset.selector;

    const textValue = element.querySelector('.upf-unit-edit')?.innerHTML;

    const newNode =
      addNode?.type === 'text' || addNode?.type === 'button'
        ? {
            ...addNode,
            '@data-edited': true,
            variantNodeId: undefined, // Remove the existing variant ID
            textValue, // Update the text value to not show the blueprint text
          }
        : addNode;

    return addPageData(parsedPageData, { addNode: newNode, ...data });
  } else {
    return parsedPageData;
  }
};

const forColumn = (pageData: string, data: StudioMessage['DuplicatePageElement']) => {
  const {
    relatedColumnElementSelectors,
    columnLayoutInnerComponentType = '',
    viewer,
  } = data.messageData.data;

  let parsedPageData = JSON.parse(pageData);
  const addNewCol = parseInt(columnLayoutInnerComponentType?.split('-')[1], 10) + 1;

  relatedColumnElementSelectors?.forEach((selector, i) => {
    const addNode = findPageData(parsedPageData, selector);
    const element = getElementFromViewer(viewer, selector);

    let newNode: PageJsonSnippetObj = { ...addNode };

    if (element && (addNode?.type === 'text' || addNode?.type === 'button')) {
      newNode = processElementNodeWithText(addNode, element);
    }

    const opt =
      i === 0 ? { addNode: newNode, addNewCol } : { addNode: newNode, addIntoCol: addNewCol };
    parsedPageData = addPageData(parsedPageData, { ...opt, ...data });
  });

  return parsedPageData;
};

const forSection = (pageData: string, data: StudioMessage['DuplicatePageElement']) => {
  const { rootNodeHost, viewer } = data.messageData.data;
  const section = findPageData(JSON.parse(pageData), rootNodeHost);
  return addSection(JSON.parse(pageData), {
    type: 'add-section',
    messageData: { elementSelector: rootNodeHost, viewer },
    objectToBeAdded: section,
    removeVariantNodeId: true,
  });
};

export const duplicateElement = (pageData: string, data: StudioMessage['DuplicatePageElement']) => {
  const { columnLayoutInnerComponentType, relatedColumnElementSelectors = [] } =
    data.messageData.data;
  if (
    columnLayoutInnerComponentType?.startsWith('column-') &&
    relatedColumnElementSelectors.length > 0
  ) {
    return forColumn(pageData, data);
  }

  if (columnLayoutInnerComponentType?.startsWith('section')) {
    return forSection(pageData, data);
  }

  return forUnit(pageData, data);
};

import { useDrag } from 'react-dnd';

import { DraggableElement } from './AddElement.styles';

import { PageJsonSnippetObj } from '~/global.types';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';

type DataItem =
  | PageJsonSnippetObj
  | Record<
      string,
      string | Record<string, string> | Record<string, string | Record<string, string>>[]
    >;

interface AddElementProps {
  component: React.ReactNode;
  'aria-label'?: string;
  dataItem?: DataItem;
  userStyle?: Record<string, string>;
}

const AddElement = ({
  component,
  dataItem = {},
  userStyle,
  'aria-label': ariaLabel,
}: AddElementProps) => {
  const [{ opacity, cursor }, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'add-page-element',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          opacity: monitor.isDragging() ? 0.5 : undefined,
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [dataItem],
  );

  return (
    <>
      <DraggableElement
        ref={dragRef}
        aria-label={ariaLabel}
        style={{
          opacity,
          cursor,
          ...userStyle,
        }}
      >
        {component}
      </DraggableElement>

      <DraggableElement
        ref={dragPreview}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: -1,
          ...userStyle,
          transform: 'translateY(-10px)',
        }}
      >
        {component}
      </DraggableElement>
    </>
  );
};

export default AddElement;

import { rem } from '@mantine/core';

interface CustomIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

const IconAccordion = ({ size, style, ...others }: CustomIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5H5V3H21V5ZM19 9H7L7 15H19V9ZM7 17H19H21V15V9V8V7H5V8V9V15V17H7ZM21 21H5V19H21V21Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconAccordion;

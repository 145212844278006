import { Menu, Tooltip } from '@mantine/core';
import {
  IconLayoutAlignCenter,
  IconLayoutAlignLeft,
  IconLayoutAlignRight,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';

const iconProps = { size: 20, stroke: 1.5 };
const alignments = [
  {
    icon: <IconLayoutAlignLeft {...iconProps} />,
    value: 'flex-start',
  },
  {
    icon: <IconLayoutAlignCenter {...iconProps} />,
    value: 'center',
  },
  {
    icon: <IconLayoutAlignRight {...iconProps} />,
    value: 'flex-end',
  },
];

type ImageAlignToolProps = {
  handleClickToEmitEvent: (key: string, value: string) => void;
  viewerData: Partial<ViewerData>;
};

const ImageAlignTool = ({ handleClickToEmitEvent, viewerData }: ImageAlignToolProps) => {
  const [alignmentValue, setAlignmentValue] = useState<string>('flex-start');

  useEffect(() => {
    setAlignmentValue(viewerData?.editorState?.alignSelf || 'flex-start');
  }, [viewerData]);

  let iconComponent;

  switch (alignmentValue) {
    case 'flex-start':
      iconComponent = alignments[0].icon;
      break;
    case 'center':
      iconComponent = alignments[1].icon;
      break;
    case 'flex-end':
      iconComponent = alignments[2].icon;
      break;
    default:
      iconComponent = alignments[0].icon;
  }

  return (
    <Menu aria-label="image-align-tool" position="bottom" zIndex={1001}>
      <Tooltip label="Alignment" color="Gray" withArrow zIndex={1001}>
        <Menu.Target>
          <StyledActionIcon variant="transparent">{iconComponent}</StyledActionIcon>
        </Menu.Target>
      </Tooltip>
      <Menu.Dropdown>
        <Menu.Label>Alignment</Menu.Label>
        {alignments.map((alignment) => (
          <Menu.Item
            aria-label={`image-align-tool-${alignment.value}`}
            key={alignment.value}
            onClick={() => {
              setAlignmentValue(alignment.value);
              handleClickToEmitEvent('alignSelf', alignment.value);
            }}
          >
            {alignment.icon}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ImageAlignTool;

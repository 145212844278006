import { PageJsonSnippetObj } from '~/global.types';
import { getCodeEmbed } from '~/services/CodeEmbedServices';

// We don't need Vite to warn this as it is deliberate dynamic import to an external url
export const getStudioHydrator = (cname: string) =>
  import(
    /* @vite-ignore */ `https://${cname.replace('*', 'studiohydrator')}/rendering/page/page.mjs`
  );

const cname =
  import.meta.env?.VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME ?? '*.test.upflowyexperience.com';

const devHydratorAccessCname = import.meta.env?.VITE_DEV_HYDRATOR_ACCESS_DOMAIN_NAME;

const devComponentLibraryCname = import.meta.env?.VITE_DEV_COMPONENT_LIBRARY_DOMAIN_NAME;

const devSiteProvisioningCname = import.meta.env?.VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME;

// Making this property into a dataset attribute will grant the hydrator the ability
// to expose variantNodeId, so that frontend can query select these easily
export const exposeVariantNodeIds = (dataStr: string) =>
  dataStr.replaceAll('"variantNodeId"', '"@data-variant-node-id"');

const renderPage = async (
  pageId = '',
  workspaceId = '',
  data: PageJsonSnippetObj,
  useFileContentsCallback = false,
): Promise<string> => {
  if (!data) {
    return '';
  }

  const getFileContentsCallback = async (fileName: string) => {
    const response = await getCodeEmbed({
      fileName,
      workspaceId,
    });
    return response.data.fileContent;
  };

  let studioHydrator;
  try {
    const { default: pageHTML } = await getStudioHydrator(devHydratorAccessCname || cname);
    studioHydrator = pageHTML;
  } catch (error) {
    console.error('Error loading studio hydrator:', error);
    return `<code>${error}</code>`;
  }

  let htmlStr;

  try {
    const dataCopyString = JSON.stringify(data);
    const dataWithNodeIds = exposeVariantNodeIds(dataCopyString);

    const siteUrl = `https://${(devSiteProvisioningCname || cname).replace(
      '*',
      workspaceId,
    )}/${workspaceId}/${pageId}`;

    const s3Url = `https://${(devComponentLibraryCname || cname).replace('*', 'components')}/`;

    htmlStr = await studioHydrator(
      dataWithNodeIds,
      siteUrl,
      s3Url,
      undefined,
      false,
      useFileContentsCallback && getFileContentsCallback,
    );
  } catch (e) {
    console.error('Error generating HTML:', e);
    return `<code>${e}</code>`;
  }

  return htmlStr;
};

export default renderPage;

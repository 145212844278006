import ShortUniqueId from 'short-unique-id';

import { PageJsonSnippetObj } from '~/global.types';

const { randomUUID } = new ShortUniqueId();

export function replaceSelectors(obj: PageJsonSnippetObj, isFirstLevel: boolean = true) {
  for (const key in obj) {
    if (key === 'selector') {
      obj[key] = isFirstLevel ? `layout-${randomUUID(8)}` : `elem-${randomUUID(8)}`;
    } else if (typeof obj[key] === 'object') {
      replaceSelectors(obj[key] as PageJsonSnippetObj, false);
    }
  }

  return obj;
}

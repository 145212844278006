import { Tooltip } from '@mantine/core';
import { IconBold, IconItalic, IconStrikethrough, IconUnderline } from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

interface SelectedTextFontToolBaseProps {
  viewerData: {
    viewer?: string;
  };
  toolKey: string;
  icon: JSX.Element;
  label: string;
}

// Export this so that is mockable/testable
// eslint-disable-next-line react-refresh/only-export-components
export const handleClickToEmitInlineToolEvent = (viewer = '', toolKey = '') => {
  const getIFrame = document.querySelector(`#${viewer}`) as HTMLIFrameElement;
  const getUi = getIFrame?.contentDocument?.querySelector(
    `[data-tool=${toolKey}]`,
  ) as HTMLButtonElement;
  getUi?.click();
};

const SelectedTextFontToolBase = ({
  viewerData,
  toolKey,
  icon,
  label,
}: SelectedTextFontToolBaseProps) => {
  return (
    <Tooltip label={label} color="Gray" withArrow zIndex={1001}>
      <StyledActionIcon
        aria-label={`${toolKey}-tool`}
        variant="transparent"
        onClick={() => handleClickToEmitInlineToolEvent(viewerData.viewer, toolKey)}
      >
        {icon}
      </StyledActionIcon>
    </Tooltip>
  );
};

export const BoldTool = ({ viewerData = {} }: Partial<SelectedTextFontToolBaseProps>) => (
  <SelectedTextFontToolBase
    viewerData={viewerData}
    toolKey="bold"
    icon={<IconBold size={20} stroke={1.5} />}
    label="Bold"
  />
);

export const ItalicTool = ({ viewerData = {} }: Partial<SelectedTextFontToolBaseProps>) => (
  <SelectedTextFontToolBase
    viewerData={viewerData}
    toolKey="italic"
    icon={<IconItalic size={20} stroke={1.5} />}
    label="Italic"
  />
);

export const StrikeThroughTool = ({ viewerData = {} }: Partial<SelectedTextFontToolBaseProps>) => (
  <SelectedTextFontToolBase
    viewerData={viewerData}
    toolKey="strikethrough"
    icon={<IconStrikethrough size={20} stroke={1.5} />}
    label="Strikethrough"
  />
);

export const UnderlineTool = ({ viewerData = {} }: Partial<SelectedTextFontToolBaseProps>) => (
  <SelectedTextFontToolBase
    viewerData={viewerData}
    toolKey="underline"
    icon={<IconUnderline size={20} stroke={1.5} />}
    label="Underline"
  />
);

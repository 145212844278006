export const getElementFromViewer = (
  viewer: string | undefined,
  elementSelector: string | undefined,
): HTMLElement | null => {
  const iframe = document.querySelector(`#${viewer}`) as HTMLIFrameElement | null;
  if (!iframe) return null;
  return iframe.contentDocument?.querySelector(
    `[data-selector="${elementSelector}"]`,
  ) as HTMLElement;
};
